@import 'theme.module.scss';

div#root {
    background-color: $background;
}

html {
    background-color: $background;
    overflow: hidden;
}

body {
    font-family: sans-serif;
    text-align: center;
    background-color: $background;
}

.menu {
    float: left;
    padding: 20px;
    padding-top: 0px; /* Menu begins at same position as CaseList */
    min-width: 140px;
    width: 150px;
    max-width: 10vw;
}

:root .menuButtonClass .MuiButton-label {
    color: $ampDarkBlue;
    justify-content: normal;
    font-family: SourceSansPro;
    font-size: 16px;
}

:root .menuButtonClass.createConsultationButton .MuiButton-label {
    text-align: left;
}

.menuButton {
    text-decoration: none;
    width: 100%;
}

.menuButton .menuButtonClass,
button.menuButtonClass {
    table-layout: fixed;
}

$sidebar-width: 200px !default;
$sidebar-collapsed-width: 50px !default;
$sidebar-bg-color: $sidebarBackground;

@import '~react-pro-sidebar/dist/scss/styles.scss';

.wrapperBox {
    display: flex;
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    width: -webkit-fill-available;
    overflow: auto;
}

.sidebarIcon {
    height: 20px;
    min-width: 33px;
    border-radius: 12px;
    line-height: 50px;
}

.versionInfo {
    position: relative;
    padding-left: 5px;
    padding-bottom: 1px;
    top: 5px;
    bottom: 0;
    display: flex;
    color: $ampDarkestGrey;
    font-size: 14px;
}

:root .MuiButton-textPrimary:hover,
:root .MuiButton-textSecondary:hover {
    background-color: transparent;
}

:root .MuiDataGrid-columnsContainer,
:root .MuiDataGrid-footerContainer {
    background-color: $ampDarkGrey;
}

:root .MuiDataGrid-columnsContainer .MuiDataGrid-columnHeaderTitle {
    font-family: SourceSansProSemiBold;
}

input.MuiOutlinedInput-inputAdornedEnd {
    font-family: SourceSansPro;
}
.PdfThumbnail {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  overflow: hidden;
  display: flex;
  place-content: center;
  padding: 8px;
  width:100%;
  height:100%;
  position: relative;
  background-color: rgb(224, 224, 224);
  cursor: pointer;
}

.Thumbnail {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}

.Thumbnail__content {
  height: 100px;
  width: 100px;
  max-height: 100px;
  min-width: 100px;
  background-color: rgb(224, 224, 224);
  border-radius: 4px;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Thumbnail__content > *:not(.Thumbnail__fileType) {
  max-height: 100px;
  min-width: 100px;
}

.Thumbnail__title {
  padding: 1px 8px;
  border-radius: 1000px;
  color: rgb(33, 38, 78);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.Thumbnail:hover > .Thumbnail__content {
  box-shadow: 0 0 0 2px rgb(33, 38, 78);
}

.Thumbnail:hover > .Thumbnail__title {
  background-color: rgb(33, 38, 78);
  color: white;
  text-shadow: none;
}

.Thumbnail__fileType {
  position: absolute;
  bottom: 4px;
  border-radius: 2px;
  right: 4px;
  padding: 4px;
  background-color: white;
  color: rgb(33, 38, 78);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
}

/* Skeleton & Loading state */

.ThumbnailSkeleton {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: progress;
  position: relative;
}

.ThumbnailSkeleton__content {
  height: 100px;
  width: 100px;
  max-height: 100px;
  min-width: 100px;
  background-color: rgb(224, 224, 224);
  border-radius: 4px;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ThumbnailSkeleton__title {
  padding: 1px 8px;
  border-radius: 1000px;
  color: rgb(153, 153, 153);
}

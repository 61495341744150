@import '../theme.module.scss';

.MuiPaper-root.statCard {
    background-color: $sidebarBackground;
    width: 275px;
    height: 100px;
    margin: 2rem;
    grid-area: 1 / 1 / 2 / 2;
}

.MuiPaper-root.privacyCard {
    background-color: $sidebarBackground;
    width: 500px;
    height: 90vh;
    margin: 2rem;
    grid-area: 1 / 2 / 2 / 3;
    overflow-y: auto;
}

.MuiPaper-root.legalCard {
    background-color: $sidebarBackground;
    width: 500px;
    height: 90vh;
    margin: 2rem;
    grid-area: 1 / 3 / 2 / 4;
    overflow-y: auto;
}

.cardTitle {
    text-transform: uppercase;
    font-family: SourceSansPro;
    color: $ampDarkBlue;
}

.cardContents {
    padding-top: 0.5rem;
    text-align: left;
    font-family: SourceSansPro;
    color: $ampDarkBlue;
}

.homeContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    width: 80vw;
    height: 100vh;
}

.Lightbox img {
  display: block;
  max-width: 100%;
  object-fit: contain;
}

.Lightbox *,
.Lightbox *::before,
.Lightbox *::after {
  box-sizing: border-box;
}

.Lightbox__html {
  overflow: hidden;
  height: 100%;
}

.Lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000000;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.15s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Lightbox__toolbar {
  align-self: flex-end;
  display: flex;
  justify-content: flex-end;
  margin: 8px;
  border-radius: 4px;
  overflow: hidden;
  gap: 8px;
  flex-shrink: 0;
  flex-wrap: wrap-reverse;
}

.Lightbox__toolbarGroup {
  align-self: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
}

.Lightbox__toolbar__button {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: white;
  cursor: pointer;
  gap: 8px;
  font-weight: 600;
}

.Lightbox__toolbar__button:hover {
  background-color: white;
  color: black;
}

.Lightbox__toolbar__button--close:hover {
  background-color: rgb(226, 31, 31);
  color: rgb(255, 255, 255);
}

.Lightbox__toolbar__button:focus {
  border: 0;
  outline: 0;
}

.Lightbox__contentContainer {
  flex-grow: 1;
  overflow: visible;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Lightbox__contentContainer--noSelect {
  user-select: none;
  -webkit-user-select: none;
}

.Lightbox__content {
  overflow: hidden;
  position: absolute;
  z-index: -1;
  margin: 8px;
  display: flex;
  cursor: grab;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Lightbox__content > * {
  pointer-events: none;
}

.Lightbox__contentContainer:active,
.Lightbox__content:active {
  cursor: grabbing;
}

.Lightbox__thumbnailContainer {
  display: flex;
  flex-direction: column;
  transition:
    transform 0.25s,
    opacity 0.2s;
  position: relative;
  padding: 8px;
}

.Lightbox__thumbnailContainer__list {
  display: flex;
  align-items: center;
  padding: 8px;
  padding-bottom: 4px;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow-y: auto;
}

.Lightbox__thumbnailContainer--hide {
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;
}

.Lightbox__thumbnailContainer__list::-webkit-scrollbar {
  width: 10px;
}

.Lightbox__thumbnailContainer__list::-webkit-scrollbar-track {
  background: transparent;
  margin: 8px;
}

.Lightbox__thumbnailContainer__list::-webkit-scrollbar-thumb {
  border-radius: 1000px;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5) inset;
}

.Lightbox__thumbnailContainer__list::-webkit-scrollbar-thumb:hover {
  box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.75) inset;
}

.Lightbox__thumbnailContainer__titleContainer {
  position: absolute;
  bottom: 100%;
  left: 16px;
  right: 16px;
  color: white;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 8px;
}

.Lightbox__thumbnailContainer__titleContainer > span {
  text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
}

.Lightbox__thumbnailContainer__titleContainer > svg {
  flex-shrink: 0;
}

.Lightbox__thumbnailContainer__titleContainer__title {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Lightbox__thumbnailContainer__titleContainer > svg {
  color: rgba(255, 255, 255, 0.726);
}

.Lightbox__content__switch {
  all: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 64px;
  width: 64px;
  border-radius: 1000px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  z-index: 1;
  transition:
    transform 0.25s,
    opacity 0.2s;
  transform-origin: 50% 50%;
}

.Lightbox__content__switch:hover {
  background-color: white;
  color: black;
}

.Lightbox__content__switch--right {
  right: 8px;
}

.Lightbox__content__switch--left {
  left: 8px;
}

.Lightbox__content__switch--right--hide {
  transform: translateX(100%) translateY(-50%);
  opacity: 0;
}

.Lightbox__content__switch--left--hide {
  transform: translateX(-100%) translateY(-50%);
  opacity: 0;
}

/* Thumbnails */

.Lightbox__Thumbnail {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  position: relative;
  flex-shrink: 0;
}

.Lightbox__Thumbnail__content {
  height: 100px;
  width: 100px;
  max-height: 100px;
  min-width: 100px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Lightbox__Thumbnail__content > *:not(.Lightbox__Thumbnail__fileType) {
  max-height: 100px;
  min-width: 100px;
}

.Lightbox__Thumbnail__title {
  padding: 1px 8px;
  border-radius: 1000px;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.Lightbox__Thumbnail:hover > .Lightbox__Thumbnail__content {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.25);
}

.Lightbox__Thumbnail:hover > .Lightbox__Thumbnail__title {
  background-color: rgba(255, 255, 255, 0.25);
  text-shadow: none;
}

.Lightbox__Thumbnail__fileType {
  position: absolute;
  bottom: 4px;
  border-radius: 2px;
  right: 4px;
  padding: 4px;
  background-color: white;
  color: rgb(33, 38, 78);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
}

.Lightbox__Thumbnail--selected > .Lightbox__Thumbnail__content {
  box-shadow: 0 0 0 2px rgb(242, 196, 15) !important;
}

.Lightbox__Thumbnail--selected > .Lightbox__Thumbnail__title {
  background-color: rgb(242, 196, 15) !important;
  color: black !important;
  text-shadow: none;
}

/* Skeleton & Loading state */

.Lightbox__ThumbnailSkeleton {
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: progress;
  position: relative;
}

.Lightbox__ThumbnailSkeleton__content {
  height: 100px;
  width: 100px;
  max-height: 100px;
  min-width: 100px;
  background-color: rgb(224, 224, 224);
  border-radius: 4px;
  overflow: hidden;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Lightbox__ThumbnailSkeleton__title {
  padding: 1px 8px;
  border-radius: 1000px;
  color: rgb(153, 153, 153);
}

@import '../theme.module.scss';

div#ohif {
    background-color: black;
}

.caseDetail {
    display: flex;
    flex-direction: column;
    padding: 22px;
    flex: 1;
    overflow: auto;
}

.archivedHeader {
    border-color: $sidebarBackground;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-family: SourceSansPro;
    text-align: center;
    color: $background;
    background-color: rgba(50, 50, 50, 50);
    margin-bottom: 10px;
    padding: 20px 0px 20px 0px;
}

.communicationList {
    border-color: $sidebarBackground;
    background-color: $background;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 22px;
    overflow: auto;
    flex: 1;
    min-height: 128px;
}

.status {
    border-width: 5px;
    border-style: solid;
    border-radius: 15px;
    margin: 10px;
    margin-bottom: 40px;
    width: 75%;
}

.status.left {
    margin-left: 0;
    margin-right: auto;
}

.status.right {
    margin-left: auto;
    margin-right: 0;
}

.status.draft {
    border-color: $status-orange;
}

.status.call {
    border-color: $status-purple;
}

.status.notread {
    border-color: $status-yellow;
}

.status.read {
    border-color: $status-green;
}

.status.unconfirmed_report {
    border-color: $status-red;
}

.status.confirmed_report {
    border-color: $status-green;
}

.communicationLabel {
    position: absolute;
    bottom: -63px;
    left: 10px;
    width: 150px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 15px;
    line-height: 10px;
}

.communicationLabel.draft {
    background-color: $status-orange;
}

.communicationLabel.call {
    background-color: $status-purple;
    color: $sidebarBackground;
}

.communicationLabel.notread {
    background-color: $status-yellow;
}

.communicationLabel.read {
    background-color: $status-green;
}

.communicationLabel.unconfirmed_report.en {
    background-color: $status-red;
}

.communicationLabel.unconfirmed_report.de {
    background-color: $status-red;
    bottom: -73px;
    height: fit-content;
}

.communicationLabel.confirmed_report {
    background-color: $status-green;
}

.communicationHeader {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: $ampDarkBlue;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.communicationSentDate {
    color: $sidebarBackground;
}

.communicationSender {
    color: $sidebarBackground;
}

.communicationStatusText {
    color: $sidebarBackground;
}

.communicationMessage {
    background-color: $sidebarBackground;
    color: black;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-top: 10px;
    padding: 10px;
}

.communicationFooter {
    position: relative;
}

.mediaContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    position: relative;
}

.mediaElement {
    margin-right: 10px;
}

.svgLoader {
    animation: spin 0.5s linear infinite;
    margin: auto;
}

.divLoader {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-top: -30px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

div h2 {
    color: rgb(42, 45, 84);
}

.form input {
    color: rgb(42, 45, 84);
}

.files.input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    text-align: center !important;
    margin: 0;
}

.files input {
    padding: 120px 65px 85px 65px;
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset 0.15s ease-in-out,
        background-color 0.15s linear;
    transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
    border: 1px solid #92b0b3;
}

.files {
    position: relative;
    width: 400px;
}

.files.image {
    pointer-events: none;
    position: absolute;
    top: 175px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #f1f1f1;
}

.files.text {
    position: absolute;
    bottom: 60px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-align: center;
}

.files.text.dicom {
    top: 270px;
}

.files.error {
    color: red;
    font-size: 1.1rem;
}

.horizontalSeparator {
    background-color: $ampDarkBlue;
    opacity: 20%;
    height: 1px;
    margin: 2px;
    width: 30%;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    grid-area: 3 / 1 / 4 / 2;
}

.verticalSeparator {
    background-color: $ampDarkBlue;
    opacity: 20%;
    width: 1px;
    height: 50px;
}

.caseHeader {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border-color: $sidebarBackground;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-family: SourceSansPro;
    text-align: center;
    color: $ampDarkBlue;
    background-color: $sidebarBackground;
    margin-bottom: 10px;
}

.blockLeft {
    grid-area: 1 / 1 / 2 / 2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr) 0.5em repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 0.5rem;
}

.casePartner {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 20px;
}

.counterpart {
    grid-area: 2 / 1 / 3 / 2;
    color: $ampDarkestGrey;
    font-size: 15px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.insurance {
    grid-area: 4 / 1 / 5 / 2;
    color: $ampDarkestGrey;
    font-size: 15px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.casePayer {
    grid-area: 5 / 1 / 6 / 2;
    font-size: 20px;
}

.parent {
    grid-area: 1 / 2 / 2 / 3;
}

.blockRight {
    grid-area: 1 / 3 / 2 / 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr) 0.5em repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 0.5rem;
}

.caseCounterpart {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 20px;
}

.contactPerson {
    grid-area: 2 / 1 / 3 / 2;
    color: $ampDarkestGrey;
    font-size: 15px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.cNumber {
    grid-area: 4 / 1 / 5 / 2;
    color: $ampDarkestGrey;
    font-size: 15px;
    padding-bottom: 0px;
    padding-top: 0px;
}

.callbackNumber {
    grid-area: 5 / 1 / 6 / 2;
    font-size: 20px;
}

.parent {
    display: grid;
    grid-area: 1 / 2 / 2 / 3;
    grid-template-columns: 1fr 1px 1fr;
    grid-template-rows: 0.9fr 1px 0.5fr 1px 0.5fr;
    grid-column-gap: 15px;
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    align-items: center;
}

.patientNameInfo {
    grid-area: 1 / 1 / 2 / 4;
}

.upperLeftInfo {
    grid-area: 3 / 1 / 4 / 2;
}

.upperHorizontalDivider {
    grid-area: 2 / 1 / 3 / 4;
}

.lowerHorizontalDivider {
    grid-area: 4 / 1 / 5 / 4;
}

.upperRightInfo {
    grid-area: 3 / 3 / 4 / 4;
}

.lowerLeftInfo {
    grid-area: 5 / 1 / 6 / 2;
}

.lowerRightInfo {
    grid-area: 5 / 3 / 6 / 4;
}

.verticalDivider {
    grid-area: 3 / 2 / 6 / 3;
}

.Resizer {
    background: gray;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

.Resizer.vertical {
    width: 11px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
    cursor: not-allowed;
}

.Resizer.disabled:hover {
    border-color: transparent;
}

/* caseFooter button colors */
:root .MuiButton-containedPrimary {
    background-color: $sidebarBackground;
    color: $ampDarkBlue;
    box-shadow: none; /* TODO: do we want this gone? */
    font-family: SourceSansPro;
}

:root .MuiButton-containedPrimary:hover {
    background-color: $ampTeal;
}

.caseDetailBox {
    display: flex;
    flex: 1;
}

.rightMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 22px;
    padding-left: 0;
    overflow: auto;
}
@import '../theme.module.scss';

div {
    font-family: SourceSansPro;
}

:root .amp.statusValue {
    padding-left: 0px;
}

.amp {
    color: $ampDarkBlue;
}

.amp.red {
    background-color: $status-red;
}

.amp.statusValue.redOpacity {
    background-color: $status-red-op25;
}

.amp.yellow {
    background-color: $status-yellow;
}

.amp.statusValue.yellowOpacity {
    background-color: $status-yellow-op25;
}

.amp.orange {
    background-color: $status-orange;
}

.amp.statusValue.orangeOpacity {
    background-color: $status-orange-op25;
}

.amp.green {
    background-color: $status-green;
}

.amp.statusValue.greenOpacity {
    background-color: $status-green-op25;
}

.amp.purple {
    background-color: $status-purple;
}

.amp.black {
    background-color: $status-black;
}

.amp.black .statusMessage {
    color: $background;
    margin-left: 15px;
}

.amp.blue {
    background-color: $status-blue;
}

.amp.statusValue.blueOpacity {
    background-color: $status-blue-op25;
}

.MuiDataGrid-root .MuiDataGrid-main .MuiDataGrid-overlay {
    background-color: transparent;
}

.MuiDataGrid-root .MuiCircularProgress-colorPrimary {
    color: $ampDarkBlue;
}

/* caseList background color */
:root .MuiDataGrid-root.MuiDataGrid-root {
    background-color: $sidebarBackground;
}

/* alternating row color */
.MuiDataGrid-row.Mui-odd {
    background-color: $background;
}

:root .MuiDataGrid-main .MuiDataGrid-columnsContainer {
    border-bottom: 1px solid $background;
}

:root .MuiDataGrid-root .MuiDataGrid-footerContainer {
    border-top: 1px solid $background;
}

@media screen and (max-device-width: 1440px) {
    #root {
        background-color: red;
    }
    .contentBody {
        padding: 22px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 83vw;
    }
}

@media screen and (min-device-width: 1441px) {
    .contentBody {
        padding: 24px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 85vw;
    }
}

// status colors
$status-red: rgb(232, 76, 61);
$status-yellow: rgb(242, 196, 15);
$status-orange: rgb(242, 155, 18);
$status-green: rgb(38, 173, 97);
$status-purple: rgb(143, 69, 173);
$status-black: rgb(54, 54, 54);
$status-blue: rgb(10, 132, 255);
$status-red-op25: rgb(235, 196, 193);
$status-yellow-op25: rgb(239, 228, 182);
$status-orange-op25: rgb(238, 218, 183);
$status-green-op25: rgb(187, 219, 200);
$status-blue-op25: rgb(182, 210, 242);
$status-purple-op25: rgb(211, 162, 188);

// amp colors
$ampDarkBlue: rgb(33, 38, 78);
$ampBrightTurquoise: rgb(89, 255, 247);
$ampTeal: rgb(0, 197, 213);
$ampPurple: rgb(157, 4, 144);
$sidebarBackground: rgb(224, 224, 224);
$background: rgb(238, 238, 238);
$ampDarkGrey: rgb(199, 199, 199);
$ampDarkestGrey: rgb(153, 153, 153);

html {
    background-color: $background;
}

@font-face {
    font-family: 'SourceSansPro';
    src: url('./../assets/fonts/SourceSansPro-Regular.ttf');
}

@font-face {
    font-family: 'SourceSansProSemiBold';
    src: url('./../assets/fonts/SourceSansPro-SemiBold.ttf');
}

@font-face {
    font-family: 'SourceSansProBold';
    src: url('./../assets/fonts/SourceSansPro-Bold.ttf');
}

:export {
    statusRed: $status-red;
    statusYellow: $status-yellow;
    statusOrange: $status-orange;
    statusGreen: $status-green;
    statusPurple: $status-purple;
    statusBlack: $status-black;
    statusBlue: $status-blue;
    statusRedOp25: $status-red-op25;
    statusYellowOp25: $status-yellow-op25;
    statusOrangeOp25: $status-orange-op25;
    statusGreenOp25: $status-green-op25;
    statusBlueOp25: $status-blue-op25;
    statusPurpleOp25: $status-purple-op25;
    ampDarkBlue: $ampDarkBlue;
    ampBrightTurquoise: $ampBrightTurquoise;
    ampTeal: $ampTeal;
    ampPurple: $ampPurple;
    sidebarBackground: $sidebarBackground;
    ampBackground: $background;
    ampDarkGrey: $ampDarkGrey;
    ampDarkestGrey: $ampDarkestGrey;
}

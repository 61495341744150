@import '../theme.module.scss';

.pageTitle {
    font-family: SourceSansPro;
    color: $ampDarkBlue;
    margin-left: 1rem;
}

.listItem div {
    font-family: SourceSansPro;
    text-align: center;
    color: $ampDarkBlue;
}

.listSubheader div {
    font-family: SourceSansPro;
    text-align: center;
    color: $ampDarkBlue;
}

.textfield div input {
    font-family: SourceSansPro;
    color: $ampDarkBlue;
    width: 20vw;
}

:root .MuiFormControl-root.newCaseFormularField {
    width: 100%;
}

.birthdateInput > div.MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.newCase {
    padding: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
}

.pageTitle {
    grid-area: 1 / 1 / 2 / 3;
}
.submitButton {
    grid-area: 7 / 1 / 8 / 3;
    padding-top: 2rem;
}

.lastNameInput {
    grid-area: 3 / 1 / 4 / 2;
}
.insuranceInput {
    grid-area: 3 / 2 / 4 / 3;
}
.firstNameInput {
    grid-area: 4 / 1 / 5 / 2;
}
.requestingClinicInput {
    grid-area: 4 / 2 / 5 / 3;
}
.birthdateInput {
    grid-area: 5 / 1 / 6 / 2;
}
.contactPersonInput {
    grid-area: 5 / 2 / 6 / 3;
}

.heightWeightGenderInput {
    grid-area: 6 / 1 / 7 / 2;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.8rem;
    grid-row-gap: 0px;
}

.heightInput {
    grid-area: 1 / 1 / 2 / 2;
    width: 8rem;
}

.weightInput {
    grid-area: 1 / 2 / 2 / 3;
    width: 8rem;
}

.genderInput {
    grid-area: 1 / 3 / 2 / 4;
    width: 8rem;
}

.callbackNumberInput {
    grid-area: 6 / 2 / 7 / 3;
}
.disciplineInput {
    grid-area: 2 / 1 / 3 / 3;
}

.healthCareServices {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.caseData {
    margin-bottom: 1.5em;
}

:root .amp.secondaryText .MuiListItemText-secondary {
    font-size: 0.775rem;
}
:root .amp.secondaryText.left .MuiListItemText-secondary,
:root .amp.secondaryText.left .MuiListItemText-primary {
    display: flex;
    justify-content: flex-end;
}

:root .amp.secondaryText.right .MuiListItemText-secondary,
:root .amp.secondaryText.right .MuiListItemText-primary {
    display: flex;
    justify-content: flex-start;
}

@import '../theme.module.scss';

.amountBadge > span {
    background-color: $ampTeal;
    color: $ampDarkBlue;
}

.fslightbox-flex-centered {
    color: $background;
}

.rightMenuButton:disabled {
    filter: grayscale(1);
    opacity: 0.33;
}